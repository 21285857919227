import React from "react";
import '../../FormStyling.css';
function FieldTypeDivider() {

    return (<>
        <hr/>
        </>
    );
}

export default FieldTypeDivider;