import React from "react";

function Successful(){

    return (
        <>
        <h1> Thank you! </h1>
            <p>Your response has successfully been submitted!</p>
            <p>I will get back to you via the email you provided regarding pricing and consultation.</p>
            <p> - Kaylea</p>
        </>
    )
}

export default Successful;